<div class="header-container">
	<div class="logo-container">
		<a
			href="#"
			class="sidebar-toggle"
			matRipple
			[matRippleUnbounded]="true"
			[matRippleCentered]="true"
			(click)="toggleSidebar()"
		>
			<nb-icon
				[icon]="(materialTheme$ | async) ? 'menu-outline' : 'menu-2-outline'"
			></nb-icon>
		</a>
		<div class="logo-wrapper">
			<img src="/assets/images/bingo-logo.png" alt="Logo" />
		</div>
	</div>
</div>

<div class="header-container">
	<nb-actions size="small">
		<nb-action
			class="user-action"
			*nbIsGranted="['view', 'user']"
			matRipple
			[matRippleUnbounded]="false"
			[matRippleCentered]="true"
		>
			<nb-user
				[nbContextMenu]="userMenu"
				[onlyPicture]="userPictureOnly"
				[name]="userName"
				picture="/assets/images/icon-user.png"
			>
			</nb-user>
		</nb-action>
	</nb-actions>
</div>
